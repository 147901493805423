<template>
  <b-modal
    :id="id"
    size="md"
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
    no-close-on-backdrop
  >
    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon :icon="taskTypeIcon"></b-icon>
        <span>Modifica {{ taskTypeLabel }}</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="
          cancel();
          cancelEventChange();
        "
      >
        &times;
      </button>
    </template>
    <!-- <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn
          @click="
            cancel();
            cancelEventChange();
          "
          size="sm"
          variant="outline-secondary"
          >Annulla</b-btn
        >
        <b-btn
            @click="handleSubmit(updateEvent)"
            :disabled="
              (fromCalendar &&
                inputVal.allDay === false &&
                !inputVal.startTime) ||
              invalid
            "
            size="sm"
            variant="outline-lisaweb"
            >Salva</b-btn
          >
      </b-form-group>
    </template> -->
    <validation-observer ref="observer3" v-slot="{ handleSubmit, invalid }">
      <b-card>
        <b-card-text>
          <base-input
            vid="title"
            :name="beFormUpdate.title.label"
            :label="beFormUpdate.title.label"
            v-model="inputVal.title"
            :placeholder="beFormUpdate.title.label"
            :rules="getRules('title', 'task')"
            maxlength="25"
          />

          <base-textarea
            vid="description"
            :name="beFormUpdate.description.label"
            :label="beFormUpdate.description.label"
            v-model="inputVal.description"
            :rules="getRules('description', 'task')"
            :placeholder="beFormUpdate.description.label"
          />

          <b-button-group
            v-b-toggle:collapse-2
            class="my-2 filter-button-group"
            ><span class="mr-2">Appuntamento</span>
            <span class="when-open">
              <b-icon icon="chevron-up"></b-icon>
            </span>
            <span class="when-closed">
              <b-icon icon="chevron-down"></b-icon>
            </span>
          </b-button-group>

          <b-collapse
            class="pb-2"
            id="collapse-2"
            :visible="fromCalendar"
          >
            <base-checkbox
              vid="allDay"
              name="allDay"
              label="Tutto il giorno"
              groupLabel=""
              v-model="inputVal.allDay"
              :chk_val="true"
              :unchk_val="false"
              v-if="inputVal.fromDate === inputVal.toDate"
            />

            <div class="row">
              <div class="col-md-6">
                <base-timepicker
                  v-if="!inputVal.allDay"
                  vid="startTime"
                  name="startTime"
                  label="Dalle ore"
                  v-model="inputVal.startTime"
                  :rules="{ required: taskType == 0 }"
                />
              </div>
              <div class="col-md-6">
                <base-timepicker
                  v-if="!inputVal.allDay"
                  vid="endTime"
                  name="endTime"
                  label="Alle ore"
                  v-model="inputVal.endTime"
                  :rules="{ required: taskType == 0 }"
                />
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <base-datepicker
                  vid="started_at"
                  name="started_at"
                  :label="beFormUpdate.start.label"
                  v-model="inputVal.fromDate"
                  :rules="{ required: taskType == 0 }"
                />
                <!-- :rules="getRules('started_at', 'task')" -->
              </div>
              <div class="col-md-6">
                <base-datepicker
                  vid="ended_at"
                  name="ended_at"
                  :label="beFormUpdate.end.label"
                  v-model="inputVal.toDate"
                  :rules="{ required: taskType == 0 }"
                />
              </div>
            </div>

            <base-datepicker
              vid="expired_at"
              :name="beFormUpdate.expired_at.label"
              :label="beFormUpdate.expired_at.label"
              :rules="getRules('expired_at', 'task')"
              v-model="inputVal.expired_at"
            />
          </b-collapse>

          <base-radio
            vid="is_public"
            :name="beFormUpdate.is_public.label"
            :label="beFormUpdate.is_public.label"
            v-model="inputVal.is_public"
            :options="beFormUpdate.is_public.options"
            :rules="getRules('is_public', 'task')"
            @select="onChangeIsPublic"
          />
          <base-select
            v-if="inputVal.is_public === 'N'"
            vid="users"
            :name="beFormUpdate.users.label"
            :label="beFormUpdate.users.label"
            v-model="inputVal.users"
            :options="beFormUpdate.users.options"
            :multiple="true"
            :taggable="false"
            :closeOnSelect="false"
            :rules="getRules('users', 'task')"
          />
          <base-select
            v-if="taskType === 1"
            :vid="beFormUpdate.status_task.label"
            :name="beFormUpdate.status_task.label"
            :label="beFormUpdate.status_task.label"
            v-model="inputVal.status_task"
            :options="beFormUpdate.status_task.options"
            :rules="getRules('status_task', 'task')"
          />

          <div style="visibility: hidden">
            <base-input
              vid="task_type"
              name="task_type"
              :value="inputVal.task_type"
            />
          </div>
        </b-card-text>
        <b-button
          @click="handleSubmit(updateEvent)"
          type="button"
          :disabled="invalid"
          variant="outline-lisaweb"
          size="sm"
          class="float-right"
        >
          Aggiorna
        </b-button>
      </b-card>
    </validation-observer>
  </b-modal>
</template>
<script>
import BaseTimepicker from "@/components/form/BaseTimepicker";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseSelect from "@/components/form/BaseSelect";
import BaseInput from "@/components/form/BaseInput";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseRadio from "@/components/form/BaseRadio";
import FormMixin from "@/mixins/FormMixin";

export default {
  mixins: [FormMixin],
  data() {
    return {
      usersOptions: [],
    };
  },
  props: {
    // taskType: {
    //   type: Number,
    //   default: 0,
    // },
    id: {
      type: String,
      default: "eventChangeModal",
    },
    value: null,
    beFormUpdate: {
      type: Object,
      default() {
        return {};
      },
    },
    beRulesUpdate: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    BaseSelect,
    BaseTimepicker,
    BaseDatepicker,
    BaseRadio,
  },
  computed: {
    fromCalendar() {
      return this.taskType === 0 ? true : false;
    },
    taskType() {
      console.debug("eventChange taskType(): ", this.inputVal.task_type);
      return this.inputVal.task_type;
    },
    taskTypeIcon() {
      let icon;
      switch (this.taskType) {
        case 0:
          icon = "calendar2-event";
          break;
        case 1:
          icon = "paperclip";
          break;
        case 2:
          icon = "journal-minus";
          break;
        default:
          icon = "question-circle";
      }
      return icon;
    },
    taskTypeLabel() {
      let label;
      switch (this.taskType) {
        case 0:
          label = "Appuntamento";
          break;
        case 1:
          label = "Attività";
          break;
        case 2:
          label = "Nota";
          break;
        default:
          label = "sconosciuto";
      }
      return label;
    },
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    // usersOptions: {
    //   get() {
    //     let tmp = this.$store.state.auth.users.filter(
    //       (user) => user.id != this.$store.state.auth.user.id
    //     );
    //     return tmp.map((u) => { return { value: u.id, text: u.username} });
    //   }
    // }
  },
  methods: {
    onChangeIsPublic(checkedOption) {
      if (checkedOption === "Y") {
        this.inputVal.users = [];
      }
      if (checkedOption === "N") {
        this.inputVal.users = [];
      }
    },
    cancelEventChange() {
      this.$emit("cancel");
    },
    updateEvent() {
      this.$emit("update");
    },
    // ...mapGetters("auth", ["user"]),
    // setUsers(users = []) {
    //   // this.beRules["task"] = this.beRulesUpdate;

    //   // const owner = this.user();
    //   const owner_id = this.$store.state.auth.user.id;
    //   // REVIEW: chi filtra gli users???? dovrebbe farlo il BE,anche perché nella editForm non ci manda owner_id
    //   // this.usersOptions = this.beFormUpdate.users.options.filter(
    //   let tmp = this.$store.state.auth.users.filter(
    //     (user) => user.id != owner_id
    //   );
    //   this.usersOptions = tmp.map((u) => { return { value: u.id, text: u.username} });
    //   // preseleziona gli users nella multiselect
    //   let selected_users = users.filter((user) => user.id != owner_id);
    //   // this.inputVal.users = selected_users.map((u) => { return { value: u.id, text: u.username} });
    //   this.$set(this.inputVal, users, selected_users.map((u) => { return { value: u.id, text: u.username} }));
    // },
    setRules(rules) {
      this.$set(this.beRules, "task", rules);
    },
  },
  beforeMount() {
    // this.beRules["task"] = this.beRulesUpdate;
    this.setRules(this.beRulesUpdate);
    // const owner = this.user();
    // this.usersOptions = this.beFormUpdate.users.options.filter(
    //   (user) => user.value !== owner.id
    // );
  },
};
</script>
<style lang="scss" scoped>
:deep(.collapse > span:last-of-type > div.form-group) {
  margin-bottom: 0;
}
:deep(.collapse + span) {
  display: block;
  margin-top: 1rem;
}
:deep(.collapse .form-group) {
  margin-bottom: 1rem;
}
</style>
